import '../src/components/App.css'
import React, { useState, useEffect } from 'react'
import { GetRandom } from './components/main'
import { Modal } from './components/modal'
import { Popup } from './components/popup'
import ReactGA from 'react-ga'
const TRACKING_ID = 'UA-216180474-1'
ReactGA.initialize(TRACKING_ID)
ReactGA.pageview(window.location.pathname + window.location.search)

function App() {
  const [modalOpen, setModalOpen] = useState(false)
  const [popupOpen, setPopupOpen] = useState(false)
  useEffect(() => {
    const timer = setTimeout(() => {
      setPopupOpen(true)
      console.log('opened')
    }, 20000)
    return () => clearTimeout(timer)
  }, [])

  return (
    <div className='background'>
      <button
        type='Button'
        className='modalButton'
        onClick={() => {
          setModalOpen(true)
        }}
      >
        <div className='menuViiva'></div>
        <div className='menuViiva'></div>
        <div className='menuViiva'></div>
      </button>

      {modalOpen && <Modal setOpenModal={setModalOpen} />}
      {popupOpen && <Popup setOpenPopup={setPopupOpen} />}
      <GetRandom></GetRandom>
    </div>
  )
}

export default App
