import React, { useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import '../../src/components/App.css'
const weaponlist = [
  '1911a1.png',
  '50ds-revolver.png',
  '590-shotgun.png',
  '870-12ga.png',
  'adar.png',
  'ak-101.png',
  'ak-102.png',
  'ak-103.png',
  'ak-104.png',
  'ak-105.png',
  'ak-545.png',
  'ak-74.png',
  'ak-74m.png',
  'ak-74n.png',
  'akm.png',
  'akmn.png',
  'akms.png',
  'akmsn.png',
  'aks-74.png',
  'aks-74n.png',
  'aks-74u.png',
  'arms_sa-58.png',
  'ash-12.png',
  'as_val_lg.png',
  'axmc-338.png',
  'dvl-10.png',
  'fn-five-seven.png',
  'fn-p90.png',
  'fn-scar.png',
  'gl40.png',
  'glock-17.png',
  'glock-18c.png',
  'golden-tt-pisol.png',
  'hk-g28.png',
  'hk-g36.png',
  'hk_416.png',
  'kedr-silenced.png',
  'ks-23.png',
  'm1a.png',
  'm32a1-msgl.png',
  'm4a1.png',
  'm700.png',
  'm9a3.png',
  'mcx-300-aac-blackout.png',
  'mdr-7.62x51.png',
  'mk-18.png',
  'mk47-mutant.png',
  'mosin.png',
  'mp-133.png',
  'mp-153.png',
  'mp-155.png',
  'mp-18.png',
  'mp-43.png',
  'mp-443-grach.png',
  'mp-5.png',
  'mp-7.png',
  'mp5-shit.png',
  'mp9.png',
  'mpx.png',
  'mts-255.png',
  'op-sks.png',
  'p226r.png',
  'pl-15.png',
  'pm-pistol.png',
  'ppsh-41.png',
  'rd-704.png',
  'rfb.png',
  'rpk-16.png',
  'rsass.png',
  'saiga-12.png',
  'saiga-9.png',
  'scar-7.62x51.png',
  'sks.png',
  'sr-25.png',
  'sr1mp.png',
  'stm-9.png',
  'sv-98.png',
  'svds.png',
  't-5000.png',
  'toz-106.png',
  'tx-15.png',
  'ump-45.png',
  'usp-45-acp.png',
  'vecto-.45-acp.png',
  'vector-9x19.png',
  'vepr-hunter.png',
  'vpo-136.png',
  'vpo-209.png',
  'vpo-215.png',
  'vss.png',
]
const armorlist = [
  'Tactec.png',
  'Tv-110.png',
  '3M.png',
  '6-b23.png',
  '6b13.png',
  '6b13m-killa.png',
  '6b2.png',
  '6b23.png',
  '6b43.png',
  'Aacpc.png',
  'Defender.png',
  'gen-4-full-protection.png',
  'gen4.png',
  'get-4-assault.png',
  'Gzhel.png',
  'Hexgrid.png',
  'kirasa.png',
  'Nfm-Thor.png',
  'Paca.png',
  'Redut-5t.png',
  'Redut-m.png',
  'Slick.png',
  'Thor.png',
  'Trooper.png',
  'Untar.png',
  'Zhuk-3.png',
  'Zhuk-6a.png',
  'Slick.png',
  'Slick.png',
]
const helmetlist = [
  '6b47.png',
  'Achhc.png',
  'Airframe.png',
  'Altyn.png',
  'Bastion.png',
  'Caiman.png',
  'Chimera.png',
  'Cowboy.png',
  'Door-kicker.png',
  'Exfil.png',
  'Fast-Mt-Replica.png',
  'Fast-Mt-Tan.png',
  'Killa.png',
  'Kiver.png',
  'Lsgz.png',
  'Panama.png',
  'Rys-T.png',
  'ssh-68.png',
  'TC-2001.png',
  'TC-800.png',
  'Ulach.png',
  'Untar.png',
  'Vulkan.png',
  'ZSh-1-2M.png',
]
const maplist = [
  'Customs.png',
  'Factory.png',
  'Interchange.png',
  'Labs.png',
  'Light-House.png',
  'Reserve.png',
  'Shoreline.png',
  'Woods.png',
]
const backPackList = [
  '6Sh118-raid.png',
  'Armybag.png',
  'Attack-2.png',
  'Berkut.png',
  'Beta-2.png',
  'Blackjack-50.png',
  'Day-pack.png',
  'Drawbridge.png',
  'Duffle-bag.png',
  'F-5.png',
  'F4.png',
  'Gunslinger.png',
  'Lk-3f.png',
  'mbss.png',
  'Mechanism.png',
  'Paratus.png',
  'pilgrim.png',
  'Pillbox.png',
  'Sanitar-bag.png',
  'Sfmp.png',
  'Sling.png',
  'T-bag.png',
  'Takedown.png',
  'Trip-zip.png',
]

const GetRandom = () => {
  const [randomArmor, setRandomArmor] = useState('Tv-110.png')
  const [randomWeapon, setRandomWeapon] = useState('scar-7.62x51.png')

  function getRandomWeapon() {
    let randomWeapon = setRandomWeapon(
      weaponlist[Math.floor(Math.random() * weaponlist.length)]
    )
  }
  let weapon = {
    weaponImg: './assets/weapons/' + randomWeapon,
    weaponName: randomWeapon.replace(/.png/g, '').toUpperCase(),
  }

  const getRandomArmor = () => {
    let randomArmor = setRandomArmor(
      armorlist[Math.floor(Math.random() * armorlist.length)]
    )
    getRandomWeapon()
    getRandomHelmet()
    getRandomMap()
    getRandomBackPack()
  }
  let armor = {
    armorImg: './assets/armors/' + randomArmor,
    armorName: randomArmor.replace(/.png/g, '').toUpperCase(),
  }
  const [randomHelmet, setRandomHelmet] = useState('Rys-T.png')

  const getRandomHelmet = () => {
    let randomHelmet = setRandomHelmet(
      helmetlist[Math.floor(Math.random() * helmetlist.length)]
    )
  }
  let helmet = {
    helmetImg: './assets/helmets/' + randomHelmet,
    helmetName: randomHelmet.replace(/.png/g, '').toUpperCase(),
  }
  const [randomMap, setRandomMap] = useState('Labs.png')

  const getRandomMap = () => {
    let randomMap = setRandomMap(
      maplist[Math.floor(Math.random() * maplist.length)]
    )
  }
  let map = {
    mapImg: './assets/maps/' + randomMap,
    mapName: randomMap.replace(/.png/g, '').toUpperCase(),
  }
  const [randomBackpack, setRandomBackpack] = useState('Blackjack-50.png')

  const getRandomBackPack = () => {
    let randomBackPack = setRandomBackpack(
      backPackList[Math.floor(Math.random() * backPackList.length)]
    )
  }
  let backpack = {
    backPackImg: './assets/backpacks/' + randomBackpack,
    backPackName: randomBackpack.replace(/.png/g, ''),
  }

  return (
    <>
      <div className=' container-fluid itemList text-white text-center'>
        <div className='header'>
          <h1 className=' text-white title'>Tarkov Loadout Generator</h1>
        </div>
        <button
          className=' RollBtn justify-content-center align-content-center '
          type='button'
          onClick={() => {
            getRandomArmor()
          }}
        >
          ROLL
        </button>
        <Row className=''>
          <Col className=' col-sm col-12'>
            <div className=''>
              <h4 className=''>{armor.armorName}</h4>
              <img src={armor.armorImg} alt={armor.armorName} className='' />
            </div>
          </Col>
          <Col className='col-sm col-12'>
            <div className=''>
              <h4>{weapon.weaponName}</h4>
              <img
                src={weapon.weaponImg}
                alt={weapon.weaponName}
                className=''
              />
            </div>
          </Col>
          <Col className='col-sm col-12 '>
            <h4>{helmet.helmetName}</h4>
            <img src={helmet.helmetImg} alt={helmet.helmetName} className='' />
          </Col>
        </Row>
        <Row className=''>
          <Col className='col-sm col-12 '>
            <div className=' '>
              <h4>{map.mapName}</h4>
              <img src={map.mapImg} alt={map.mapName} />
            </div>
          </Col>
          <Col className='col-sm col-12'>
            <div className=' b'>
              <h4>{backpack.backPackName}</h4>
              <img src={backpack.backPackImg} alt={backpack.backPackName} />
            </div>
          </Col>
        </Row>
      </div>
    </>
  )
}

export { GetRandom }
