import { React } from 'react'
import './modal.css'
function Modal({ setOpenModal }) {
  return (
    <div className='modalBackground'>
      <div className='modalContainer'>
        <div className='titleCloseBtn'>
          <button
            onClick={() => {
              setOpenModal(false)
            }}
          >
            X
          </button>
        </div>
        <div className='title'>
          <h1>Tarkov Random Loadout Lottery</h1>
        </div>
        <div className='body'>
          <ul>
            <h3>Contact me</h3>
            <li>Disord: Jone#1619 </li>
            <li>
              {' '}
              Twitter:{' '}
              <a
                href='https://twitter.com/DiscoNd '
                target='_blank'
                rel='noopener'
              >
                DiscoNd
              </a>{' '}
            </li>
            <li>
              {' '}
              <a
                href='https://wallpaperbat.com/escape-from-tarkov-wallpapers'
                target='_blank'
                rel='noopener'
              >
                Background Image
              </a>
            </li>
            <li>
              {' '}
              <a href='https://logomakr.com/' target='_blank' rel='noopener'>
                Logo
              </a>
            </li>
          </ul>
        </div>
        <div className='footer'>
          <button
            onClick={() => {
              setOpenModal(false)
            }}
            id='cancelBtn'
          >
            Close
          </button>
        </div>
      </div>
    </div>
  )
}
export { Modal }
