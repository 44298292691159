import { React } from 'react'

import './Popup.css'

function Popup({ setOpenPopup }) {
  return (
    <div className='overlay'>
      <div
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        <div className='modalContainer'>
          <div className='popupImg'>
            <a
              href='https://c8ce29omsbw1cvaqy4n5nnj5en.hop.clickbank.net'
              target='_blank'
              rel='noopener'
            >
              <img
                className='kuva'
                style={{ height: '100%', width: '100%' }}
                src='./assets/popupImages/ad.jpg'
                alt=''
              />
            </a>
          </div>
          <div className='btnContainer'>
            <button
              className='btnOutline'
              type='button'
              onClick={() => {
                setOpenPopup(false)
              }}
            >
              {' '}
              Close
              {/* <span className='bold'>Close</span> */}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
export { Popup }
